import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { useState } from "react"
import { down } from "styled-breakpoints"
import styled from "styled-components"
import FilterItem from "../components/common/FilterItem"
import FormInput from "../components/common/FormInput"
import MapViewer from "../components/common/MapViewer"
import PointListItem from "../components/common/PointListItem"
import Section from "../components/common/Section"
import Layout from "../components/layout"
import Seo from "../components/seo"
import MembersEn from "../images/svgs/about-incorporates-en.inline.svg"
import "mapbox-gl/dist/mapbox-gl.css"
import { useWindowSize } from "usehooks-ts"

const Container = styled.div`
  h1 {
    margin-top: 82px;
    ${down("lg")} {
      margin-top: 48px;
    }
  }
  & .content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;

    ${down("lg")} {
      flex-direction: column-reverse;
    }
  }
  & .user-types {
    display: flex;
    width: auto;

    ${down("lg")} {
      width: 100%;
      justify-content: space-between;
    }
  }

  & .filterItem {
    min-width: 100px;
    height: 26px;
    text-align: center;
    ${down("lg")} {
      /* min-width: unset; */
      height: 40px;
      margin: 0 4px;
      min-width: 32%;
      width: 32%;
    }
  }
`
const MapContainer = styled.div`
  display: flex;
  padding-bottom: 104px;

  ${down("lg")} {
    flex-direction: column;
  }

  ${down("md")} {
    padding-bottom: 0;
  }

  .members-list {
    height: 620px;
    overflow: auto;
    ${down("lg")} {
      display: flex;
      height: unset;
    }
  }
  .list-container {
    padding-right: 24px;
    ${down("lg")} {
      display: flex;
      width: auto;
      overflow-y: auto;
    }
  }
`
const Members = props => {
  const { t, i18n } = useTranslation()
  const [activeMarker, setActiveMarker] = useState()
  const size = useWindowSize()

  const userTypes = [
    { name: t("common:all"), value: "Όλα" },
    { name: t("common:local"), value: "Τοπική", color: "#FF8275" },
    { name: t("t:sectoral"), value: "Κλαδική", color: "#00A6A8" },
  ]

  const [userType, setUserType] = React.useState("Όλα")
  const [keyword, setKeyword] = React.useState("")

  const members = props.data.allPrismicMember.nodes
  const filteredMembers = members
    .filter(member => member.data.name.text.includes(keyword))
    .filter(member =>
      userType === "Όλα" ? true : member.data.type === userType
    )

  const points = activeMarker
    ? filteredMembers.filter(
        x =>
          x.data.location.latitude === activeMarker.latitude &&
          x.data.location.longitude === activeMarker.longitude
      )
    : filteredMembers

  return (
    <Layout location={props.location} type="primary">
      <Seo title="Members" />
      <Section className="homehero-section">
        <Container className="container">
          <h1>{t("members:title")}</h1>
          <div className="content-header">
            <div className="user-types">
              {userTypes.map(item =>
                item.color ? (
                  <FilterItem
                    key={item.value}
                    style={{ margin: 0 }}
                    selected={item.value === userType}
                    onClick={() => setUserType(item.value)}
                    color={item.color}
                  >
                    {item.name}
                  </FilterItem>
                ) : (
                  <FilterItem
                    key={item.value}
                    style={{ margin: 0 }}
                    selected={item.value === userType}
                    onClick={() => setUserType(item.value)}
                  >
                    {item.name}
                  </FilterItem>
                )
              )}
            </div>
            <div className="col-12 col-lg-5">
              <FormInput
                placeholder={t("common:searchHere")}
                id="input_search"
                type="search"
                value={keyword}
                onChange={e => setKeyword(e.target.value)}
              />
            </div>
          </div>
          <MapContainer className="d-flex">
            {Boolean(size.width) && (
              <MapViewer
                markers={filteredMembers.map(member => ({
                  ...member.data.location,
                  label: member.data.address.text,
                  type: member.data.type,
                }))}
                onPinClick={setActiveMarker}
                activeMarker={activeMarker}
                height={size.width < 640 ? "350px" : "610px"}
              />
            )}
            <div
              css={`
                ${down("md")} {
                  margin-top: 32px;
                }
              `}
              className="col-sm-12 col-lg-4 members-list"
            >
              <div className="list-container">
                {points.map(point => (
                  <PointListItem
                    key={point.prismicId}
                    filtered={keyword !== "" || activeMarker}
                    member={point}
                  />
                ))}
              </div>
            </div>
          </MapContainer>
        </Container>
      </Section>
      {i18n.resolvedLanguage === "en-us" && (
        <footer>
          <div className="category-menu border-top">
            <div
              className="container"
              css={`
                display: flex;
                justify-content: center;
              `}
            >
              <MembersEn />
            </div>
          </div>
        </footer>
      )}
    </Layout>
  )
}
export default Members

export const query = graphql`
  query MembersQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allPrismicMember(
      sort: { fields: data___name___text }
      filter: {
        lang: { eq: $language }
        data: { location: { latitude: { ne: null }, longitude: { ne: null } } }
      }
      limit: 1000
    ) {
      nodes {
        prismicId
        data {
          type
          address {
            text
          }
          fax
          telephone
          location {
            latitude
            longitude
          }
          email
          name {
            text
          }
          members {
            full_name
            position
          }
        }
      }
    }
  }
`
